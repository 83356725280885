import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import loadingImage from "./loading.gif";
import "./App.css";
import Navbar from "./components/Layout/Navbar";
import Home from "./components/Home/Home";
import Footer from "./components/Layout/Footer";

function App() {
  const MyLoadingScreen = () => {
    return (
      <img
        src={loadingImage}
        alt="loader"
        className="mx-auto d-block align-items-center"
      />
    );
  };

  return (
    <div>
      <Router>
        <div className="App">
          <Navbar />
          <Routes maxLoadingTime={700} loadingScreen={MyLoadingScreen}>
            <Route path="/" element={<Home />} loading />

            {/* <Route path="*" element={<PagetNotFound />} loading /> */}
          </Routes>
          <Footer />
        </div>
      </Router>
    </div>
  );
}

export default App;
