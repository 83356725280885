import React, { useEffect, useRef } from "react";
import "./multiRangeSlider.css";
import Tooltip from "react-bootstrap/Tooltip";
import { OverlayTrigger } from "react-bootstrap";
import { currencyFormat } from "../../utils/constant";

const MultiRangeSlider = ({ savingsCalculations }) => {
  // const [minVal, setMinVal] = useState(savingsCalculations.low_percentage);
  // const [maxVal, setMaxVal] = useState(savingsCalculations.high_percentage);
  const minValRef = useRef(null);
  const maxValRef = useRef(null);
  const range = useRef(null);

  useEffect(() => {
    if (minValRef.current && maxValRef.current) {
      if (range.current) {
        range.current.style.left = "5%";
        range.current.style.width = "90%";
        // range.current.style.left = `${minVal}%`;
        // range.current.style.width = `${maxVal - minVal}%`;
      }
    }
  }, []);

  return (
    <div className="slider-wrap">
      <h1>
      {`${savingsCalculations.low_percentage.toFixed(1)}%`}
        {/* {"Low"}
        <br /> */}
        {/* <span>{`${savingsCalculations.low_percentage.toFixed(1)}%`}</span> */}
      </h1>
      <div className="slider-container">
        <input
          type="range"
          min={0}
          max={100}
          value={5}
          ref={minValRef}
          disabled={true}
          className="thumb thumb--zindex-4"
        />
        <input
          type="range"
          min={0}
          max={100}
          value={95}
          ref={maxValRef}
          disabled={true}
          className="thumb thumb--zindex-3"
        />

        <div className="slider">
          <div className="slider__track" />
          <OverlayTrigger
            key={"top"}
            placement={"top"}
            show={true}
            overlay={
              <Tooltip id={`tooltip-${"top"}`}>
                {`$ ${currencyFormat(savingsCalculations.savings_average)}`}
              </Tooltip>
            }
          >
            <div ref={range} className="slider__range" />
          </OverlayTrigger>
        </div>
      </div>
      <h1>
      {`${savingsCalculations.high_percentage.toFixed(1)}%`}
        {/* {"High"}
        <br /> */}
        {/* <span>{`${savingsCalculations.high_percentage.toFixed(1)}%`}</span> */}
      </h1>
    </div>
  );
};

export default MultiRangeSlider;
