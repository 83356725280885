import React from 'react'
import { Button, Container } from 'react-bootstrap'
import MultiRangeSlider from "./MultiRangeSlider";

const SavingsOpportunities = ({ savingsCalculations }) => {

    return (<Container className='finished'>
        <div className='text-area'>
            <h4>{"Thank you! "}</h4>
            <p>{"For allowing the eTeam SOW Solutions team to help you identify SOW savings"}<br />{" and spend management opportunities within your organization! "}</p>
            <span>{"Below are our findings based on the inputs you submitted: "}</span>

        </div>
        <div className='request-demo-section-wrap'>
            <Container className='request-demo-section'>
                <h1>{"Minimum Savings Estimate"}</h1>
                <hr />
                <MultiRangeSlider savingsCalculations={savingsCalculations} />

                <h3>{"The eTeam SOW Solutions team will email you the full "}<br />{" report shortly. In this report will be our recommended "}<br />{" solution to help you realize these savings."}</h3>
                <p>{'Would you like to schedule a no obligation consultation with eTeam SOW Solutions to '}<br />{' learn how we can deliver these savings and improvements to your organization?'}</p>
                <Button>{"Schedule SOW Consultation"}</Button>
                <p>{"A custom SOW Savings Assessment is being prepared for you and will be sent via email shortly."}</p>
            </Container>
        </div>
    </Container>)

}

export default SavingsOpportunities;