const masterJson = {
  category: [
    {
      key: "Freelancer / IC SOW engagements",
      value: "Freelancer / IC SOW engagements",
      title: "Freelancer / IC SOW engagements",
    },
    {
      key: "IT Consulting",
      value: "IT Consulting",
      title: "IT Consulting",
    },
    {
      key: "Management Consulting",
      value: "Management Consulting",
      title: "Management Consulting",
    },
    {
      key: "General / Various Consulting",
      value: "General / Various Consulting",
      title: "General / Various Consulting",
    },
    {
      key: "Project Delivery Services",
      value: "Project Delivery Services",
      title: "Project Delivery Services",
    },
    {
      key: "Managed Services / BPO",
      value: "Managed Services / BPO",
      title: "Managed Services / BPO",
    },
    {
      key: "Engineering",
      value: "Engineering",
      title: "Engineering",
    },
    {
      key: "Audit / Reg Compliance",
      value: "Audit / Reg Compliance",
      title: "Audit / Reg Compliance",
    },
    {
      key: "Other Professional Services",
      value: "Other Professional Services",
      title: "Other Professional Services",
    },
    {
      key: "Tax Advisory",
      value: "Tax Advisory",
      title: "Tax Advisory",
    },
    {
      key: "Legal",
      value: "Legal",
      title: "Legal",
    },
    {
      key: "Healthcare",
      value: "Healthcare",
      title: "Healthcare",
    },
    {
      key: "Field Services",
      value: "Field Services",
      title: "Field Services",
    },
    {
      key: "Equipment Maintenance",
      value: "Equipment Maintenance",
      title: "Equipment Maintenance",
    },
    {
      key: "Combination of these",
      value: "Combination of these",
      title: "Combination of these",
    },
    {
      key: "Unclassified or unmanaged SOW engagements",
      value: "Unclassified or unmanaged SOW engagements",
      title: "Unclassified or unmanaged SOW engagements",
    },
  ],
  spend: [],
  vendors: [
    {
      key: "< 5",
      value: 1.25,
      title: "< 5",
    },
    {
      key: "5 - 10",
      value: 1.15,
      title: "5 - 10",
    },
    {
      key: "10 - 50",
      value: 1,
      title: "10 - 50",
    },
    {
      key: "50 - 100",
      value: 1.25,
      title: "50 - 100",
    },
    {
      key: "> 100",
      value: 1.5,
      title: "> 100",
    },
  ],
  program: [
    {
      key: "Yes, Including this SOW Spend",
      value: 1,
      title: "Yes, Including this SOW Spend",
    },
    {
      key: "Yes, But not this SOW Spend",
      value: 3,
      title: "Yes, But not this SOW Spend",
    },
    {
      key: "No",
      value: 5,
      title: "No",
    },
    {
      key: "I don't know",
      value: 5,
      title: "I don't know",
    },
  ],
  visibiliy: [
    {
      key: "Very Low Visibility into SOW Operations",
      value: 8,
      title: "Very Low Visibility into SOW Operations",
    },
    {
      key: "Medium Visiblity into the SOW Operations",
      value: 6,
      title: "Medium Visiblity into the SOW Operations",
    },
    {
      key: "High Visiblity for this SOW Operations",
      value: 4,
      title: "High Visiblity for this SOW Operations",
    },
  ],
  control: [
    {
      key: "Very Little Financial Control",
      value: 5,
      title: "Very Little Financial Control",
    },
    {
      key: "Medium Financial Control",
      value: 2.5,
      title: "Medium Financial Control",
    },
    {
      key: "High Level of Controls",
      value: 2,
      title: "High Level of Controls",
    },
  ],
  sourcing: [
    {
      key: "very minimal sourcing policies and practices",
      value: 6,
      title: "Minimal Sourcing Policies and Practices",
    },
    {
      key: "medium level sourcing",
      value: 4,
      title: "Medium Level of Sourcing Policies and Practices",
    },
    {
      key: "high level of sourcing and procedural governance over this SOW spend",
      value: 2,
      title:
        "High Level of Sourcing Governance Over this SOW Spend",
    },
  ],
};

const StepCntent = [
  {
    step: 1,
    menuHeading: "Category",
    title: "1. What type of services spend are you inquiring about?",
    CardSubtitle: "This will be the SOW spend scope for this assessment.",
    inputType: "select",
    buttonText: "Next",
    nextStep: 2,
    previousStep: 0,
    payloadKey: "sow_catagory",
    options: masterJson.category,
  },
  {
    step: 2,
    menuHeading: "Spend",
    title: "2. Enter approximate amount of annual spend (US $) in this category ",
    CardSubtitle: "(round to nearest $100K)",
    inputType: "input",
    buttonText: "Next",
    nextStep: 3,
    previousStep: 1,
    payloadKey: "annual_spend",
    options: masterJson.spend,
  },
  {
    step: 3,
    menuHeading: "Vendors",
    title:
      "3. Enter approximate number of vendors providing this service or supporting this category / these SOW engagements.",
    CardSubtitle: "",
    inputType: "select",
    buttonText: "Next",
    nextStep: 4,
    previousStep: 2,
    payloadKey: "vendors",
    options: masterJson.vendors,
  },
  {
    step: 4,
    menuHeading: "Program",
    title:
      "4. Does your organization currently use a VMS or MSP to manage your contingent labor spend?",
    CardSubtitle: "",
    inputType: "select",
    buttonText: "Next",
    nextStep: 5,
    previousStep: 3,
    payloadKey: "sow_vms_or_msp_",
    options: masterJson.program,
  },
  {
    step: 5,
    menuHeading: "Visibility",
    title:
      "5. Tell us about the spend and vendor data Visibility you estimate your organization currently has with regards to the SOW spend noted above? ",
    CardSubtitle: "",
    inputType: "select",
    buttonText: "Next",
    nextStep: 6,
    previousStep: 4,
    payloadKey: "spend_visability",
    options: masterJson.visibiliy,
  },
  {
    step: 6,
    menuHeading: "Control",
    title:
      "6. Tell us about the Control over financial and treasury functions (budgeting, invoicing, payments) you estimate your organization currently has with regards to the SOW spend noted above?",
    CardSubtitle: "",
    inputType: "select",
    buttonText: "Next",
    nextStep: 7,
    previousStep: 5,
    payloadKey: "control",
    options: masterJson.control,
  },
  {
    step: 7,
    menuHeading: "Sourcing",
    title:
      "7. Are there sourcing policies and established sourcing practices that currently govern the procurement / sourcing / management of the SOW spend in the previous questions?",
    CardSubtitle: "",
    inputType: "select",
    buttonText: "Finish",
    nextStep: 8,
    previousStep: 6,
    payloadKey: "sourcing",
    options: masterJson.sourcing,
  },
];

const getSavingsConfiguration = async (
  savingsCalculations,
  setSavingsCalculations,
  item,
  inputValue,
  payload
) => {
  let tempCalc = JSON.parse(JSON.stringify(savingsCalculations));
  switch (item.step) {
    case 2:
      let spendValue = parseInt(inputValue);
      tempCalc = {
        ...tempCalc,
        spend: spendValue < 5000000 ? 0.6 : spendValue > 50000000 ? 1.25 : 1,
      };
      break;

    case 3:
      tempCalc = {
        ...tempCalc,
        vendors: Number(inputValue),
      };
      break;

    case 4:
      tempCalc = {
        ...tempCalc,
        program: Number(inputValue),
      };
      break;

    case 5:
      tempCalc = {
        ...tempCalc,
        visibiliy: Number(inputValue),
      };
      break;

    case 6:
      tempCalc = {
        ...tempCalc,
        control: Number(inputValue),
      };
      break;

    case 7:
      let sourcing = Number(inputValue);
      let rawSavings =
        tempCalc.program + tempCalc.visibiliy + tempCalc.control + sourcing;
      let adjustedSavings = rawSavings * tempCalc.spend * tempCalc.vendors;
      let cap = adjustedSavings > 40 ? "Yes" : "No";
      let finalResult =
        cap === "No" ? adjustedSavings : "40% and possibly more";
      let low_percentage = finalResult < 0.02 ? 2.5 : finalResult * 0.6;
      let low_savings_amount = low_percentage * Number(payload.annual_spend);
      let high_percentage = finalResult;
      let high_savings_amount = high_percentage * Number(payload.annual_spend);
      let savings_average = (low_savings_amount + high_savings_amount) / 2;

      tempCalc = {
        ...tempCalc,
        sourcing,
        rawSavings,
        adjustedSavings,
        cap,
        finalResult,
        low_percentage,
        low_savings_amount,
        high_percentage,
        high_savings_amount,
        savings_average,
      };
      break;

    default:
      break;
  }
  // console.log(tempCalc);
  setSavingsCalculations(tempCalc);
};

const currencyFormat = (value) => {
  return new Intl.NumberFormat("en-US", {
    useGrouping: true, // Use commas for grouping
    minimumFractionDigits: 0, // No decimals
  }).format(parseFloat(value).toFixed(0))
};

const footerList = [
  "100% guaranteed commercial terms compliance",
  "50-70% greater SOW spend visibility and control",
  "SOW solution designed by and for Procurement"
];

export { StepCntent, getSavingsConfiguration, currencyFormat, footerList};
