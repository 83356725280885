import React from "react";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./layout.css";
import { Facebook, LinkedIn } from "../assets/svg/svg-icons";
import logo from "../assets/images/logo.png";

const Navbar = () => {
  const topNav = (
    <div className="top-nav">
      <h4>
        {
          "Certified Diversity and Security Cleared Organization | GDPR Compliant"
        }
      </h4>

      <div className="socailIconLinks">
        <Link>
          <Facebook />
        </Link>
        <Link>
          <LinkedIn />
        </Link>
      </div>
    </div>
  );
  const maiNav = (
    <nav className="navbar-section ">
      <div className="navbar-container">
        <Link className="navbar-brand" to="/">
          <Image src={logo} alt="Logo" />
        </Link>
        <div className="desktop w-full">
          {/* <div className="w-full about-desktop-menu ">{aboutRouteLinks}</div> */}
          <Link className={`nav-item nav-link-color brand-hover`} to="/">
            {"HOME"}
          </Link>
          <Link className={`nav-item nav-link-color brand-hover`} to="/">
            {"CAREERS"}
          </Link>
          <Link className={`nav-item nav-link-color brand-hover`} to="/">
            {"SOLUTIONS"}
          </Link>
          <Link className={`nav-item nav-link-color brand-hover`} to="/">
            {"CLIENTS"}
          </Link>
          <Link className={`nav-item nav-link-color brand-hover`} to="/">
            {"ABOUT US"}
          </Link>
          <Link className={`nav-item nav-link-color brand-hover`} to="/">
            {"eINSIGHTS"}
          </Link>
          <Link className={`nav-item nav-link-color brand-hover`} to="/">
            {"CONTACTS"}
          </Link>
        </div>
        <div className="mobile burger-menu-wrap">
          {/* <CustomOffCanvas
            buttonContant={<BurgerMenu />}
            placement={"end"}
            className="aboutus-ofcanvas"
            backdropClassName="aboutus-backdrop"
            bodyBsPrefix="aboutus-ofcanvas-body"
            isOffCanvasOpen={showAboutUsMenu}
            OffcanvasShow={handleAboutUsMenuShow}
            OffcanvasClose={handleAboutUsMenuClose}
          >
            <div className="w-full about-mobile-menu ">{aboutRouteLinks}</div>
          </CustomOffCanvas> */}
        </div>
      </div>
    </nav>
  );

  return (
    <>
      {topNav}
      {maiNav}
    </>
  );
};

export default Navbar;
