import React from "react";
import { Form, InputGroup } from "react-bootstrap";

const CustomInput = ({ item, inputValue, setInputValue, errorText, setErrorText }) => {
  const changeInput = (value) => {
    setInputValue(value);
    setErrorText("");
  };

  if (item.inputType === "select") {
    return (
      <Form.Group className="custom-input">
        <Form.Select
          className="custom-input-class"
          aria-label="Default select example"
          value={inputValue}
          onChange={(e) => changeInput(e.target.value)}
        >
          <option className={"text-capitalize"} key={"0"} value={""}>
            Select an option
          </option>
          {item.options.map((el, i) => (
            <option className={"text-capitalize"} key={el.key} value={el.value}>
              {el.title}
            </option>
          ))}
        </Form.Select>
        {errorText && <p className="invalid">{errorText}</p>}
      </Form.Group>
    );
  } else {
    return (
      <Form.Group className="custom-input">
        <InputGroup className="custom-input-class text-input ">
          <InputGroup.Text>{"$"}</InputGroup.Text>
          <Form.Control
            type="text"
            aria-describedby="amount"
            value={inputValue}
            onChange={(e) => changeInput(e.target.value.replace(/[^0-9]/g, ""))}
          />
        </InputGroup>

        {errorText && <p className="invalid">{errorText}</p>}
      </Form.Group>
    );
  }
};

export default CustomInput;
