import React, { useState } from "react";
import "./home.css";
import Steps from "./Steps.js";

function Home() {
  const [showTagLine, setShowTagLine] = useState(true);
  const bannerSection = (
    <div className="bannerSection">
      <h1>{"SOW calculator"}</h1>
    </div>
  );

  const headingSection = (
    <div className="heading-section">
      <h1>{"SOW Savings Calculator "}</h1>
      {showTagLine && (
        <p>
          {
            "In the entry form below you will be asked a few simple questions about the services "
          }
          <br />
          {
            "spend (aka SOW spend) you are requesting eTeam to provide a savings estimate for."
          }
        </p>
      )}
    </div>
  );

  return (
    <>
      {bannerSection}
      {headingSection}
      <Steps setShowTagLine={setShowTagLine} />
    </>
  );
}

export default Home;
