export const Facebook = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 10H16V13H13V20H10V13H7V10H10V8.745C10 7.556 10.374 6.054 11.118 5.233C11.862 4.41 12.791 4 13.904 4H16V7H13.9C13.402 7 13 7.402 13 7.899V10Z" fill="white" />
    </svg>
  );
};
export const LinkedIn = () => {
  return (

    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.959 13.7194V21.0984H17.681V14.2134C17.681 12.4834 17.062 11.3034 15.514 11.3034C14.332 11.3034 13.628 12.0994 13.319 12.8684C13.206 13.1434 13.177 13.5264 13.177 13.9114V21.0984H8.897C8.897 21.0984 8.955 9.43837 8.897 8.22937H13.177V10.0534L13.149 10.0954H13.177V10.0534C13.745 9.17837 14.76 7.92737 17.033 7.92737C19.848 7.92737 21.959 9.76737 21.959 13.7194ZM4.421 2.02637C2.958 2.02637 2 2.98637 2 4.24937C2 5.48437 2.93 6.47337 4.365 6.47337H4.393C5.886 6.47337 6.813 5.48437 6.813 4.24937C6.787 2.98637 5.886 2.02637 4.421 2.02637ZM2.254 21.0984H6.532V8.22937H2.254V21.0984Z" fill="white" />
    </svg>

  );
};