import React from "react";
import "./layout.css";
import { footerList } from "../../utils/constant";

const Footer = () => {
  return (
    <section className="footer-section">
      <p>{"eTeam SOW Compliance Solution"}</p>
      <ul>
        {footerList.map((el, i) => (
            <li key={i}>{el}</li>
        ))}
      </ul>
    </section>
  );
};

export default Footer;
