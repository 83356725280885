import React, { useRef, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import SavingsOpportunities from "./SavingsOpportunities";
import CustomInput from "./CustomInput";
import { currencyFormat, getSavingsConfiguration, StepCntent } from "../../utils/constant";
import axios from "axios";
// import HubspotContactForm from "./HubSpotForm";

const Steps = ({ setShowTagLine }) => {
  const refSteps = useRef([]);
  const [step, setStep] = useState(1);
  const [show, setShow] = useState(false);
  const [finished, setFinished] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [payload, setPayload] = useState({
    firstname: "",
    lastname: "",
    company: "",
    email: "",
  });
  const [error, setError] = useState({});
  const [errorText, setErrorText] = useState("");
  const [savingsCalculations, setSavingsCalculations] = useState({
    category: "",
    spend: "",
    vendors: "",
    program: "",
    visibiliy: "",
    control: "",
    sourcing: "",
    rawSavings: "",
    adjustedSavings: "",
    cap: "",
    finalResult: "",
    low_percentage: "",
    low_savings_amount: "",
    high_percentage: "",
    high_savings_amount: "",
    savings_average: "",
  });
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const changeInput = (item, value) => {
    setPayload({ ...payload, [item]: value });
    setError({ ...error, [item]: "" });
  };

  const stepChange = (item) => {
    setShowTagLine(item.nextStep > 1 ? false : true);
    if (item.step < 7) {
      // console.log(inputValue);
      if (!inputValue) {
        setErrorText("This field is required.");
        return;
      } else if (
        item.inputType === "input" &&
        (parseInt(inputValue.replace(/,/, "")) < 100000 ||
          parseInt(inputValue.replace(/,/, "")) > 9999999999)
      ) {
        setErrorText("Please enter a dollar amount, rounded to nearest $100K.");
        return;
      }
      setPayload({ ...payload, [item.payloadKey]: inputValue });
      getSavingsConfiguration(
        savingsCalculations,
        setSavingsCalculations,
        item,
        inputValue
      );
      setInputValue("");
      setErrorText("");
      setStep(item.nextStep);
    } else if (item.step === 7) {
      setPayload({ ...payload, [item.payloadKey]: inputValue });
      getSavingsConfiguration(
        savingsCalculations,
        setSavingsCalculations,
        item,
        inputValue,
        payload
      );
      handleShow();
    }
    refSteps.current?.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  const onSubmitForm = async () => {
    try {
      if (!payload.firstname) {
        setError({ ...error, firstname: "First Name is required." });
        return;
      }
      if (!payload.lastname) {
        setError({ ...error, lastname: "Last Name is required." });
        return;
      }
      if (!payload.company) {
        setError({ ...error, company: "Company is required." });
        return;
      }
      if (!payload.email) {
        setError({ ...error, email: "Email is required." });
        return;
      }

      let tempPayload = {
        ...payload,
        low_percentage: parseFloat(savingsCalculations.low_percentage).toFixed(1) + "%",
        low_savings_amount: "$" + currencyFormat(savingsCalculations.low_savings_amount),
        high_percentage: parseFloat(savingsCalculations.high_percentage).toFixed(1) + "%",
        high_savings_amount: "$" + currencyFormat(savingsCalculations.high_savings_amount),
        savings_average: "$" + currencyFormat(savingsCalculations.savings_average),
      };
      tempPayload = Object.keys(tempPayload).map((el) => {
        return {
          objectTypeId: "0-1",
          name: el,
          value: tempPayload[el],
        };
      });

      const response = await axios.post(
        "https://api.hsforms.com/submissions/v3/integration/submit/6587979/96cebc68-4dff-41e1-b151-f5b4b31db595",
        { fields: tempPayload }
      );
      if (response) {
        handleClose();
        setFinished(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section className="steps-section">
      {finished ? (
        <SavingsOpportunities savingsCalculations={savingsCalculations} />
      ) : (
        <Row className="m-0" ref={refSteps}>
          {/* fixed-width-col */}
          <Col xs={3} className=" menu-col">
            {StepCntent.map((item, index) => {
              return (
                <div className="side-menu" key={index}>
                  <div
                    className={`item ${step >= item.step ? "active" : "not-active"} `}
                  >
                    {step > item.step ? (
                      <span className="complete-step">
                        <svg
                          width="20"
                          height="16"
                          viewBox="0 0 20 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0 8L2.625 5.33333L7.875 10.6667L18.375 0L21 2.66667L7.875 16L0 8Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                    ) : (
                      <span className="active-step">{item.step}</span>
                    )}
                    {/* <span>{item.step}</span> */}

                    <p />
                    <Button disabled>{item.menuHeading}</Button>
                  </div>
                </div>
              );
            })}
          </Col>
          {/* flex-grow-col */}
          <Col xs={8} lg={7} xxl={6} className="input-col">
            {StepCntent.map((item, index) => {
              return (
                <div
                  className={`main-content ${step === item.step ? "show" : "hide"}`}
                  key={index}
                >
                  <h2>{item.title}</h2>
                  <p>{item.CardSubtitle}</p>
                  <hr />
                  <CustomInput
                    item={item}
                    inputValue={
                      item.inputType === "input" && inputValue !== ""
                        ? new Intl.NumberFormat("en-US", {
                            useGrouping: true, // Use commas for grouping
                            minimumFractionDigits: 0, // No decimals
                          }).format(parseInt(inputValue))
                        : inputValue
                    }
                    setInputValue={setInputValue}
                    errorText={errorText}
                    setErrorText={setErrorText}
                  />
                  <Button
                    className="form-submit-btn"
                    onClick={() => stepChange(item)}
                  >
                    {item.buttonText}
                  </Button>
                </div>
              );
            })}

            <Modal
              show={show}
              onHide={handleClose}
              className="custom-modal-class"
            >
              <Modal.Body>
                <div className="text-area">
                  <h4>{"Thank you! "}</h4>
                  <p>
                    {
                      "For allowing the eTeam SOW Solutions team to help you identify SOW savings"
                    }
                    <br />
                    {
                      " and spend management opportunities within your organization! "
                    }
                  </p>
                  <span>
                    {
                      "Please share your business email address with us below and we will "
                    }
                    <br />
                    {"present our findings to you on the next screen."}
                  </span>
                </div>
                {/* {show && <HubspotContactForm 
                  region="na1"
                  portalId="6587979"
                  formId="96cebc68-4dff-41e1-b151-f5b4b31db595"
                  target="#hubspotForm"
                />} */}

                <Form className="custom-form">
                  <Form.Group className="mb-3" controlId="firstName">
                    <Form.Label>{"First Name"}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter First Name"
                      value={payload.firstname}
                      required={true}
                      onChange={(e) =>
                        changeInput(
                          "firstname",
                          e.target.value.replace(/[^a-zA-Z]/g, "")
                        )
                      }
                    />
                    {error?.firstname && (<span className="error-text">{error.firstname}</span>)}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="lastName">
                    <Form.Label>{"Last Name"}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Last Name"
                      value={payload.lastname}
                      required={true}
                      onChange={(e) =>
                        changeInput(
                          "lastname",
                          e.target.value.replace(/[^a-zA-Z]/g, "")
                        )
                      }
                    />
                    {error?.lastname && (<span className="error-text">{error.lastname}</span>)}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="company">
                    <Form.Label>{"Company"}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Company"
                      value={payload.company}
                      required={true}
                      onChange={(e) =>
                        changeInput(
                          "company",
                          e.target.value.replace(/[^a-zA-Z0-9]/g, "")
                        )
                      }
                    />
                    {error?.company && (<span className="error-text">{error.company}</span>)}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="email">
                    <Form.Label>{"Email Address"}</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter Email"
                      value={payload.email}
                      required={true}
                      onChange={(e) => changeInput("email", e.target.value)}
                    />
                    {error?.email && (<span className="error-text">{error.email}</span>)}
                  </Form.Group>
                  <Button type="button" onClick={() => onSubmitForm()}>{"Submit"}</Button>
                </Form>
              </Modal.Body>
            </Modal>
          </Col>
          {/* reomve if needed */}
          <Col xs={1} lg={2} xxl={3} ></Col>
        </Row>
      )}
    </section>
  );
};

export default Steps;
